#app{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    #app-inside{
        margin-top: 65px;
    }
    .noMargin{
        margin: 0 !important;
    }

    .mobile-inside{
        margin-bottom: 3.5rem;
    }

    #app-content{
        margin: 0.5rem 1rem;
    }
}