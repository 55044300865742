#view-Login{
    height: 100%;

    .aboutContent{
        margin: auto;
        width: fit-content;
        font-size: 12px;
        :first-child{
            margin-right: 10px;
        }
    }

    #col-register {
        background-image:linear-gradient(grey, #333), url(../../assets/toggle_img.jpg);
        background-blend-mode: multiply ;
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;

        #btnInscription {
            padding: 10px;
            font-family: 'Fira Sans', sans-serif;
            text-align: center;
            text-decoration: none;
            background-color:  #08376B ;
            color:rgb(238, 238, 238);
            border-radius: 5px;
            cursor: pointer;
            width:fit-content
        }
    }

    #col-login{
        #logo {
        width: 300px;
        }

        #networks{
            margin: 0 auto;
            span {
                padding: 10px;
            }
        }
        
        #loginForm{
            display: flex;
            flex-direction: column;
            .btnConnexion {
                padding: 10px;
                font-family: 'Fira Sans', sans-serif;
                text-align: center;
                text-decoration: none;
                border: none;
                background-color:  #08376B ;
                color:rgb(238, 238, 238);
                border-radius: 3px;
                cursor: pointer;
                width:200px;
                margin: 5px auto;
                }
                .btnInscriptionDisplayMobile {
                padding: 10px;
                font-family: 'Fira Sans', sans-serif;
                text-align: center;
                text-decoration: none;
                border: none;
                background-color:  #676768 ;
                color:rgb(238, 238, 238);
                border-radius: 3px;
                cursor: pointer;
                width:200px;
                margin: 5px auto;
                }
        }

        #component-FormLog {
            width: 50%;
            margin:0 auto;

            #mdpLink{
                color: rgb(34, 33, 33);
                font-size: small;
            }
            .fieldLabel{
                font-weight: bold;
                font-size: 16px;
            }
        }

        #cookies {
            font-size: xx-small;
            text-align: justify;
            padding: 0 5rem;
            color: #818990;
        }
       
    }
   
    

    

    

    

    
    

    
    
}
