#component-navbar{
    width: 100%;
    position: fixed;
    bottom: 0;
    
    .navContent{
        background: #FCFCFC;
        border-top: 0.5px solid #CDCDCD;
        box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 0.5px 0.5px rgba(0, 0, 0, 0.2);
        border-radius: 20px 20px 0px 0px;
    }
    
    .Mui-selected {
        color: #AB1111 !important ;
    }
}