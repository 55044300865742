#view-home{
    display: flex;
    flex-direction: column;
    height: 100%;
    
    #homeTitle{
        margin-top: 2rem;
        text-align: center;
    }
    
    #dashBoardContent{
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: space-around;
    }
    
    .dashBoardItem{
        text-decoration: none;
        color: black;
        .dashBoardCard{
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            height: 12rem;
            width: 12rem;
            background-color: rgb(252, 252, 252) !important;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            margin-bottom: 20px;
        }
        .dbcMobile{
            height: 8rem;
            width: auto;
            margin-bottom: 10px;
        }
    }
    .dbiMobile{
        width: 90%;
    }

    .dashBoardCard{
        span{
            margin-top: 5px;
        }
    }
    
    .dashBoardItem:hover{
        transform: translateY(-8px);
    }
    
    .bi-briefcase, .bi-file-earmark-person, .bi-pie-chart{
        height: 3rem;
        width: 3rem;
        border-radius: 10px;
    }
    
    .mobileTopMargin{
        margin-top: 1rem !important;
    }
}