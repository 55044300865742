#component-offreEmploi {
    width: 70%;
    background: #f7f7f7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 2rem;
    padding: 2.5rem 1rem;

    li {
        list-style: none;
    }
    
    img {
        border: 2px solid #b4b4b4;
        box-sizing: border-box;
        border-radius: 50%;
        width: 200px;
        height: 200px;
    }
}

@media screen and (min-width: 800px) {
}
