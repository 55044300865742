#view-about {
    padding: 2rem 4rem;
    text-align: center;
  #networks {
    display: flex;
    .networkItem {
      text-decoration: none;
      color: black;
      margin: 5px;
    }
  }

  .img{
    height: 8rem;
  }

  h1,
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  #contacts {
    display: flex;
    flex-wrap: wrap;

    .contactPart {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 2rem;
      margin-top: 1rem;
      flex: 1;
    }
    .contactSeparator {
        margin-top: 1rem;
        width: 1px;
        height: 100%;
        background-color: maroon;
    }
    .contactForm {
      width: 100%;
      .fieldGroup {
        text-align: start;
      }
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0;
    #contacts{
      flex-direction: column;
      .contactSeparator{
        width: 100%;
        height: 1px;
      }
    }
  }
}
