#view-legalMentions {
  padding: 2rem 4rem;
  text-align: center;
  .img {
    height: 8rem;
  }

  @media screen and (max-width: 700px) {
    padding: 0;
  }
}
