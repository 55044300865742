#component-topBar{
    background-color: #FCFCFC;
    padding: 15px;
    border-bottom: rgba(0, 0, 0, 0.3) 0.5px solid;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    position : fixed;
    z-index : 1;
    top : 0;
    display :  flex;
    align-items : center;
}

.browser{
    text-align : start;
    display :  flex;
    justify-content : space-between;
    align-items : center;
    height : 65px;
}

.mobile{
    text-align : center;
    height : 55px;
}

.browserLogo{
    width : 150px;
}

.mobileLogo{
    width : 120px;
}