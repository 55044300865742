#view-ToggleLog{
    height: 100%;
    
    .aboutContent{
        margin: auto;
        width: fit-content;
        font-size: 12px;
        :first-child{
            margin-right: 10px;
        }
    }

    #col-illustration {
        background-size: cover;
        background-position: center;
        background-image: url("/assets/toggle_img.jpg");
    }

    #logo {
        width: 300px;
    }

    #toggleLoginTitle{
        margin-bottom: 5rem;
    }
     
    #toggleLog-wrapper {
        margin: 10px auto;
        text-align: center;
        width: 100%;
        }
    
     .toggle-button {
        padding: 15px 70px;
        margin:10px 4px;
        color: #08376B;
        font-family: 'Fira Sans', sans-serif;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        text-decoration: none;
        display:inline-block;
        border:1px solid #08376B ;
        width: 15rem;
        border-radius: 10px;
    }
    
    .toggle-button::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: -1;
        -webkit-transform: scaleY(.1);
        transform: scaleX(.1);
        transition: all .4s
        }
    .toggle-button:hover{
        color:rgb(238, 238, 238) !important;
        cursor: pointer;    
    }
    .toggle-button>a:hover{
        color:rgb(238, 238, 238) !important;
    }
    
    .toggle-button:hover::before{
        opacity: 1;
        background-color: #08376B;
        border-radius: 10px;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        transition: -webkit-transform .6s cubic-bezier(.08, .35, .13, 1.02), opacity .4s;
        transition: transform .6s cubic-bezier(.08, .35, .13, 1.02), opacity;
        }
        
    #cookies {
        font-size: xx-small;
        text-align: justify;
        padding: 0 5rem;
        color: #818990;
    }
    
    @media screen and (max-width : 800px ) {
        #col-illustration {
            display: none;
        }
        .toggle-button{
            background-color: #08376B;
            color: white;
        }
    
        #col-content{
            background: rgb(247, 220, 220);
            background: linear-gradient(0deg, rgb(255, 192, 192) 0%, rgba(255,255,255,1) 100%)
        }
    
        #toggleLoginTitle{
            font-weight: bold;
        }
    
        #cookies{
            color: black;
        }
    }
      
}
  