#component-sidebar{
    background: #FCFCFC;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    width: 12rem;
    margin-top: 63px;

    #sidebarIMG {
        height: 125px;
        width: 125px;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    }

    .noDecorationLink{
        text-decoration: none;
        color: black;
    }
}